import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from './App';
import BloodPressure from './price_chats/BloodPressure';
import BloodSugar from './price_chats/BloodSugar';
import Chelesterol from './price_chats/Chelesterol';
import HearRate from './price_chats/HearRate';
import Offer from './price_chats/Offer';
import SignUp from './price_chats/SignUp';
import SignUpOffer from './price_chats/SignUpOffer';
import Stress from './price_chats/Stress';
import { url } from './url';

const Home = () => {
    const [apps,setApps]=useState([]);

    const {sitestate,dispatch,captureClick}=useContext(SiteContext)

    // console.log(captureClick)

    useEffect(()=>{
        axios.get(url+'monetize/plans/apps?search_term=')
        .then(res=>{
            setApps(res.data.result.data)
            

        }).catch(e=>{
            console.log(e)
        })

    },[])

    const renderapps=apps.map((app,index)=>(

        <>
        {app.name!='Web'?<>
        <option value={app.id+'|'+app.code+'|'+app.amount}>{app.name} ${app.amount} Available only on Android</option>
        </>:null}
       
        </>
        
    )) 

    
    return ( 
        <div className='flex p-6 flex-col'>
            
            
            <div className='flex md:flex-row flex-col w-full border p-10 border-black rounded-lg'>
                <div className='md:w-1/2  flex'>
                    <div className='text-blue-400 md:text-3xl text-2xl font-black'>
                    Get unlimited access to <span className='text-black'>Daily<span className='text-red-500'>+</span> </span>

                    <span className=' font-normal'>Mobile Apps with our android .APK</span>
                    </div>
                    
                </div>
                <div className='md:w-1/2 md:border-l flex flex-col pl-3 space-y-2 md:pt-0 pt-5'>

                    <div className='flex flex-row space-x-2'>
                        <div>
                        <i class="fa-solid fa-caret-down text-3xl"></i>
                        </div>
                        <div className='my-auto'>
                        Click (arrow down icon) to select your Daily+ apps

                        </div>
                    

                    </div>
                    <div className='flex flex-row space-x-2'>
                        <div>
                        <i class="fa-solid fa-download text-2xl"></i>
                        </div>
                        <div className='my-auto'>
                        Download.apk to activate Daily+ app

                        </div>
                    

                    </div>
                    <div className='flex flex-row space-x-2'>
                        <div>
                        <i class="fa-solid fa-crown text-2xl"></i>
                        </div>
                        <div className='my-auto'>
                        Purchase a premium version to unlock all features

                        </div>
                    

                    </div>
                   
                    
                    

                </div>

            </div>
            <div>
                
            </div>

            {/* <div className='w-1/4 my-3'>

            <Link type="button" to="/payment" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Test Payment flow</Link>
            </div> */}

            <div className='flex flex-col md:flex-row '>
                <div className='w-1/2'>
                    <div className='text-2xl text-green-500 my-auto'>
                         Available Only on Android

                    </div>

                </div>
                <div className='w-1/2'>
                <div className='py-2 mx-auto'>
                <button onClick={()=>dispatch({type:'selected_app',value:"offer"})} type="button" class="text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none ">Check our Offer</button>
                </div>

                </div>

            </div>

            

            <div class='p-3 border border-black relative mt-12'>
            <h2 class="absolute -top-1/2 translate-y-1/2 bg-white">Select Your app from the following List</h2>
            <div>
                

            {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select an option</label> */}
            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " 
            onChange={(e)=>dispatch({type:'selected_app',value:e.target.value})} value={sitestate.selected_app}>
            <option value="">Select...</option>
            {/* <option value="offer">Offer</option> */}
            {/* <option value="heart_rate">Heart Rate Monitor</option>
            <option value="blood_pressure">Blood Pressure Monitor</option>
            <option value="stress">Stress Monitor</option>
            <option value="blood_sugar">Blood Sugar Monitor</option>
            
            <option value="cholesterol">Cholesterol Monitor</option> */}
            {renderapps}
            
            </select>


                
            </div>
        </div>
        <div>
            <SignUp />
            <SignUpOffer />


        </div>
        
        

        

        {sitestate.single_app.includes('heart') ? <>
        <HearRate />
        
        </>:null}
        {sitestate.single_app=='offer' ? <>
        <Offer />
        
        </>:null}
        {sitestate.single_app.includes('pressure') ? <>
        <BloodPressure />
        
        </>:null}
        {sitestate.single_app.includes('stress') ? <>
        <Stress />
        
        </>:null}
        {sitestate.single_app.includes('sugar') ? <>
        <BloodSugar />
        
        </>:null}
        {sitestate.single_app.includes('cholesterol') ? <>
        <Chelesterol />
        
        </>:null}
        

        </div>
    );
}
 
export default Home;