import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../App';

import Blood from '../assets/Asset 121@2.1x.png'
import Premium from '../assets/Asset 128@2.1x.png'
import SignUp from './SignUp';
// import SignUp from './SignUp';


const HearRate = () => {
    const {sitestate,dispatch,captureClick}=useContext(SiteContext)
    const [amount, setAmount]=useState(0)

    useEffect(()=>{
        var app_array=sitestate.single_app.split('|')
        setAmount(app_array[2])



    },[])

   
    return (
        <div>
            {/* <SignUp /> */}
            
            
            <table className='w-full mt-6 table-auto'>
                <tr>
                    <td className='w-1/2'>
                        

                    </td>
                    <td className='w-1/4 p-5 bg-blue-500 m-auto'>
                        <div className='mx-auto flex md:text-2xl md:font-black text-white'>
                            Free
                            </div>

                    </td>
                    <td className='w-1/4 p-5 bg-blue-600 m-auto'>
                       <div className='mx-auto flex md:text-2xl md:font-black text-white' >
                       Premium
                        </div> 

                    </td>

                </tr>
                <tr className="border-y-2">
                    <td className='w-1/2'>
                        
                    <img src={Blood} className="md:h-16 h-12" />
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                        <center>
                        <button 
                    type="button" 
                    onClick={()=>captureClick('free')}

                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm md:px-5 px-3 py-1.5 md:py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"> 
                    {/* <i class="fa-brands fa-android text-2xl"></i>  */}
                    <span className="py-auto"> Download .APK</span> </button>

                        </center>
                   

                    </td>
                    <td className='w-1/4 bg-blue-200'>
                    <center>

{/* <img src={Premium} className="md:h-12 h-10 cursor-pointer" onClick={()=>captureClick('premium')} />  */}
<div className='flex md:flex-row flex-col md:py-3 border-2 border-blue-500 md:border-0 rounded-l-lg rounded-lg md:ml-4 md:mx-4 md:px-4 cursor-pointer' onClick={()=>captureClick('premium')} >
    <div className='bg-blue-500 text-white flex flex-row md:py-2 border border-blue-500 md:rounded-l-lg'>
    <i class="fa-solid fa-crown my-auto md:mr-1"></i> 
    
    <span>Purchase</span>
    </div>
    <div className='text-red-500 font-extrabold my-auto border md:py-2 md:rounded-r-lg md:px-4 border-blue-500'>
    {amount}

    </div>

</div>

    </center>

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>

                        
                    <i class="fa-solid fa-expand text-blue-500 text-2xl"></i> 10 heart rate level checks for 3 days
                        
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                    <center>

                    <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i>
                    </center>

                    </td>
                    <td className='w-1/4 mx-auto bg-blue-200'>

                        <center>

                        <div classname="mx-auto w-full">
                        <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i>
                        </div>
                        </center>

                    
                    

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>
                        
                    <i class="fa-solid fa-message text-blue-500 text-2xl"></i> Get insights from your heart rate level
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                   <center> <i class="fa-solid fa-circle-check text-green-500 text-2xl"></i></center>

                    </td>
                    <td className='w-1/4 mx-auto bg-blue-200'>

                        <center>
                        <div classname="mx-auto w-full">
                        <i class="fa-solid fa-circle-check text-green-500 text-2xl mx-auto"></i>
                        </div>

                        </center>

                    
                    

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>
                        
                    <i class="fa-solid fa-infinity text-blue-500 text-2xl"></i> Unlimited heart rate check
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                        <center>

                    <i class="fa-solid fa-circle-xmark text-2xl text-red-500 "></i>
                        </center>

                    </td>
                    <td className='w-1/4 mx-auto bg-blue-200'>
                        <center>

                        <div classname="mx-auto w-full">
                        <i class="fa-solid fa-circle-check text-green-500 text-2xl mx-auto"></i>
                        </div>
                        </center>

                    
                    

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>
                    {/* <i class="fa-solid fa-calendar-day"></i> */}
                    <i class="fa-solid fa-calendar-day text-blue-500 text-2xl"></i> Review history in calendar view
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                        <center>

                    <i class="fa-solid fa-circle-xmark text-2xl text-red-500 "></i>
                        </center>

                    </td>
                    <td className='w-1/4 mx-auto bg-blue-200'>
                        <center>

                        <div classname="mx-auto w-full">
                        <i class="fa-solid fa-circle-check text-green-500 text-2xl mx-auto"></i>
                        </div>
                        </center>

                    
                    

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>
                    {/* <i class="fa-solid fa-chart-column"></i> */}
                    <i class="fa-solid fa-chart-column text-blue-500 text-2xl"></i> Get an in-depth graphic analysis of your results
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                        <center>
                    <i class="fa-solid fa-circle-xmark text-2xl text-red-500 "></i>

                        </center>

                    </td>
                    <td className='w-1/4 mx-auto bg-blue-200'>
                        <center>

                        <div classname="mx-auto w-full">
                        <i class="fa-solid fa-circle-check text-green-500 text-2xl mx-auto"></i>
                        </div>
                        </center>

                    
                    

                    </td>

                </tr>
                <tr className="border-b-2">
                    <td className='w-1/2 py-4'>
                        

                    <i class="fa-regular fa-message text-blue-500"></i> <span className='text-blue-600 underline font-bold'>What's new?</span>
                        
                    
                    </td>
                    <td className='w-1/4 bg-blue-100'>
                    

                    </td>
                    
                    <td className='w-1/4 mx-auto bg-blue-200'>

                        
                    
                    

                    </td>

                </tr>
            </table>
        </div>
      );
}
 
export default HearRate;