import React, { useContext, useEffect, useState } from 'react';
import AD from '../assets/Asset 116@2.1x.png'
import Purchase from '../assets/Asset 128@2.1x.png'
import HR from '../assets/Asset 121@2.1x.png'
import Stress from '../assets/Asset 120@2.1x.png'
import BP from '../assets/Asset 119@2.1x.png'
import BS from '../assets/Asset 118@2.1x.png'
import CL from '../assets/Asset 117@2.1x.png'
import { SiteContext } from '../App';
const Offer = () => {
    const [hr, setHr] = React.useState(false);
    const [bp, setBp] = React.useState(false);
    const [stress, setStress] = React.useState(false);
    const [bs, setBs] = React.useState(false);
    const [cl, setCl] = React.useState(false);
    const [amount,setAmount]=useState(0);
    const [apps,setApps]=useState(0)

    const [selected,setSelectedApps]=useState([])

    const [error,setError]=useState('')

    const {sitestate,dispatch,captureClick}=useContext(SiteContext)

    
    useEffect(()=>{
        buyBundle()

        if(amount>9.9 || apps>1 ){
            setAmount(9.98)


        }

    },[amount])

    useEffect(()=>{
        testFn(hr)
        
        if(hr){
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setHr(false)
            //     return
            // }
            setApps(apps+1)
            setAmount(amount+4.99)
        }else{
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setHr(false)
            //     return
            // }
            if(apps>0){
                setApps(apps-1)
                setAmount(amount-4.99)

            }

        }

        console.log(apps)
    },[hr])
    useEffect(()=>{
        testFn(stress)
        
        
        if(stress){
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setStress(false)
            //     return
            // }

            setApps(apps+1)
            setAmount(amount+4.99)

        }else{
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setStress(false)
            //     return
            // }
            if(apps>0){
                setApps(apps-1)
                setAmount(amount-4.99)

            }

        }

       
    },[stress])
    useEffect(()=>{
        testFn(bp)
        
        if(bp){
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setBp(false)
            //     return
            // }

            setApps(apps+1)
            setAmount(amount+4.99)

        }else{
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setBp(false)
            //     return
            // }
            if(apps>0){
                setApps(apps-1)
                setAmount(amount-4.99)

            }

        }

       
    },[bp])
    useEffect(()=>{
        testFn(cl)
        
        if(cl){
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setCl(false)
            //     return
            // }

            setApps(apps+1)
            setAmount(amount+4.99)

        }else{
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setCl(false)
            //     return
            // }
            if(apps>0){
                setApps(apps-1)
                setAmount(amount-4.99)

            }

        }

       
    },[cl])
    useEffect(()=>{
        testFn(bs)
        
        if(bs){
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setBs(false)
            //     return
            // }
            setApps(apps+1)
            setAmount(amount+4.99)

        }else{
            // if(apps>2){
            //     setError('You can only 3 apps')
            //     setBs(false)
            //     return
            // }
            if(apps>0){
                setApps(apps-1)
                setAmount(amount-4.99)

            }

        }

       
    },[bs])


    const testFn=(n)=>{
        // console.log(n)
    }

    const handleHr = () => {

        if(!hr){
            // Selection being done
            // 1. check apps
            if(apps>2){
                // This is the 3rd app
                setError('You can only select 3 apps')
            }else{
                setHr(!hr);

            }
        }else{
            // alert(apps)
        
        setHr(!hr);
        // alert(apps)

        }
        
        };
    const handleStress = () => {

        if(!stress){
            // Selection being done
            // 1. check apps
            if(apps>2){
                // This is the 3rd app
                setError('You can only select 3 apps')
            }else{
                setStress(!stress);

            }
        }else{
            setStress(!stress);
        }
       
        
      };
  
    const handleBp = () => {

        if(!bp){
            // Selection being done
            // 1. check apps
            if(apps>2){
                // This is the 3rd app
                setError('You can only select 3 apps')
            }else{
                setBp(!bp);

            }
        }else{
            setBp(!bp);
        }
        
        
        
      };
    const handleBs = () => {

        if(!bs){
            // Selection being done
            // 1. check apps
            if(apps>2){
                // This is the 3rd app
                setError('You can only select 3 apps')
            }else{
                setBs(!bs);

            }
        }else{
            setBs(!bs);
        }
        
        
      };
    const handleCl = () => {
        if(!cl){
            // Selection being done
            // 1. check apps
            if(apps>2){
                // This is the 3rd app
                setError('You can only select 3 apps')
            }else{
                setCl(!cl);

            }
        }else{
            setCl(!cl);
        }
        
        
      };

      const updateApps=()=>{
        setApps([])

        if(!hr){
            // setApps(apps=>[...apps,'3'])
            setAmount(amount+4.99)

        }else{
            setAmount(amount-4.99)

        }
        if(!stress){
            setApps(apps,[...apps,'4'])

        }

        console.log(apps)

        

      }

      const buyBundle=()=>{
        

        // if(count==0){
        //     buyBundle(1)

            

        // }
        setSelectedApps([])
        if(hr){
            setSelectedApps(selected=>[...selected,3])

        }
        if(bp){
            setSelectedApps(selected=>[...selected,1])

        }
        if(stress){
            setSelectedApps(selected=>[...selected,4])

        }
        if(bs){
            setSelectedApps(selected=>[...selected,2])

        }
        if(cl){
            setSelectedApps(selected=>[...selected,5])

        }

        // Set selected apps id

        


        // open signup dialog

      }

      const signin=()=>{
        console.log(selected)
        dispatch({type:'selected_apps',value:selected})
        dispatch({type:'sign_up_offer',value:true})
        dispatch({type:'plan',value:'premium'})

      }
   
    return ( <div className='flex flex-col'>

        <div>
        <img src={AD} className=" h-40 w-full" />
        </div>

        <div className='flex flex-row flex-wrap space-x-2 mt-3 mx-auto'>
            
        <div className='flex flex-col'>
                <div>
                    <img src={BP} className="h-20" />

                </div>
                <div class="flex items-center mb-4 mx-auto mt-3">
                    {/* <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " /> */}
                    <input
                        type="checkbox"
                        checked={bp}
                        onChange={handleBp}
                        />
                    
                </div>

            </div>
            <div className='flex flex-col'>
                <div>
                    <img src={CL} className="h-20" />

                </div>
                <div class="flex items-center mb-4 mx-auto mt-3">
                    {/* <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " /> */}

                    <input
                        type="checkbox"
                        checked={cl}
                        onChange={handleCl}
                        />
                    
                </div>

            </div>
            <div className='flex flex-col'>
                <div>
                    <img src={HR} className="h-20" />

                </div>
                <div class="flex items-center mb-4 mx-auto mt-3">
                    {/* <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " /> */}

                    <input
                        type="checkbox"
                        checked={hr}
                        onChange={handleHr}
                        />
                    
                </div>

            </div>
            <div className='flex flex-col'>
                <div>
                    <img src={Stress} className="h-20" />

                </div>
                <div class="flex items-center mb-4 mx-auto mt-3">
                    {/* <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " /> */}
                    <input
                        type="checkbox"
                        checked={stress}
                        onChange={handleStress}
                        />
                    
                    
                </div>

            </div>
            
            <div className='flex flex-col'>
                <div>
                    <img src={BS} className="h-20" />

                </div>
                <div class="flex items-center mb-4 mx-auto mt-3">
                    {/* <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " /> */}
                    <input
                        type="checkbox"
                        checked={bs}
                        onChange={handleBs}
                        />
                    
                </div>

            </div>
            
        </div>
        <div>
        Selected Apps {apps}
        </div>
        <div className='text-red-500 font-bold'>
         {error}
        </div>


        {apps<3?<>
        <span className='text-blue-500 flex mx-auto font-extrabold'>Select 3 Apps</span>

        </>:<>
        <div className='flex mx-auto'>
            {/* <img src={Purchase} /> */}
            <div className='flex md:flex-row flex-col md:py-3 border-2 border-blue-500 md:border-0 rounded-l-lg rounded-lg md:ml-4 md:mx-4 md:px-4 cursor-pointer' onClick={()=>signin()} >
    <div className='bg-blue-500 text-white flex flex-row md:py-2 border border-blue-500 md:rounded-l-lg'>
    <i class="fa-solid fa-crown my-auto md:mr-1"></i> 

    
    
    <span>Purchase</span>
    </div>
    <div className='text-red-500 font-extrabold my-auto border md:py-2 md:rounded-r-lg md:px-4 border-blue-500'>
    {amount}

    </div>

</div>

        </div>

        </>}

        

    
    </div> );
}
 
export default Offer;