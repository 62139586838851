import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { createContext, useReducer } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Payment from './payment';
export const SiteContext= createContext()

const initialstate= {
  sign_up:false,
  sign_up_offer:false,
  single_app:'',
  plan:'',
  apss:[]
  // sign:false,
  

}

const sitereducer = (state, action) => {
  switch (action.type) {
    case 'sign_up':
          return {...state,sign_up : action.value}
    case 'sign_up_offer':
          return {...state,sign_up_offer : action.value}
    case 'plan':
          return {...state,plan : action.value}
    case 'selected_app':
      // console.log('I am here'+action.value)
          return {...state,single_app : action.value}
    case 'selected_apps':
      // console.log('I am here'+action.value)
          return {...state,apss : action.value}
    default:
      return state

  }

}

function App() {
  const [sitestate,dispatch]=useReducer(sitereducer,initialstate)

  const captureClick=(plan)=>{
    // alert(plan);
    dispatch({type:'sign_up',value:true})
    dispatch({type:'plan',value:plan})
  }
  return (

    

<SiteContext.Provider value={{sitestate,dispatch,captureClick}}>
       {/* <Home /> */}
       <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/payment" element={<Payment />} />
       
       </Routes>

    </SiteContext.Provider>
   
    
   
  );
}

export default App;
