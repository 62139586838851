import React, { useContext, useState } from "react";
import { SiteContext } from "../App";

import StressLogo from '../assets/Asset 125@2.1x.png'
import ChelestorolLogo from '../assets/Asset 122@2.1x.png'
import BSLogo from '../assets/Asset 123@2.1x.png'
import BPLogo from '../assets/Asset 124@2.1x.png'
import HRLogo from '../assets/Asset 126@2.1x.png'
import DocLogo from '../assets/Docsun health logo.png'
import axios from "axios";
import { url } from "../url";
import { Navigate } from "react-router-dom";
import { Tab } from '@headlessui/react'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Modal() {

  const [firstname,setFirstName]=useState('')
  const [lastname,setLastName]=useState('')
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [cpassword,setCPassword]=useState('')

  const [spin, setSpin]=useState(false)
  const [success, setSuccess]=useState(false)
  const [error, setError]=useState('')
  const [errors,setErrors]=useState([])

  const rendererror=errors.map((error,index)=>(
      <ul key={index}>
        <li>{error}</li>

      </ul>

  ));

  let [categories] = useState({
    "Create Account": [
      {
        id: 1,
        title: 'Does drinking coffee make you smarter?',
        date: '5h ago',
        commentCount: 5,
        shareCount: 2,
      },
      {
        id: 2,
        title: "So you've bought coffee... now what?",
        date: '2h ago',
        commentCount: 3,
        shareCount: 2,
      },
    ],
    "Log In": [
      {
        id: 1,
        title: 'Is tech making coffee better or worse?',
        date: 'Jan 7',
        commentCount: 29,
        shareCount: 16,
      },
      {
        id: 2,
        title: 'The most innovative things happening in coffee',
        date: 'Mar 19',
        commentCount: 24,
        shareCount: 12,
      },
    ],
    
  })




  

  const {sitestate,dispatch}=useContext(SiteContext)

  const signUp=()=>{

    
    // alert("hello")

    // console.log(sitestate);
    var app_array=sitestate.single_app.split('|')

    
    

    setError('')

    if(!firstname || !lastname || !email || !password || !cpassword){
      setError('Fill all the fields')

      return
    }

    if(cpassword != password){
      setError('Password Mismatch')

      return
 
    }

    setSpin(true)

    console.log('Sign in')

    let array=sitestate.apss

    let len=array.length
    var myappid=''
    if(len!=0){
      myappid=sitestate.apss
      

    }else{
      myappid=app_array[0]
      

    }
    console.log(myappid)

    // return

    axios.post(url+'monetize/user/register',{
      name: firstname+' '+lastname,
      email,
      password,
      app_id:myappid,
      plan_type:sitestate.plan,
      // app_name:app_array[1]

    })
    .then(res=>{

      // console.log(res);
      setSuccess(true)

      dispatch({type:'sign_up', value:false})

    }).catch(e=>{
      console.log('update')
      
      setError(e.response.data.message)

    }).then(e=>{
      setSpin(false)
      // setError('')
    })
  }
  const login=()=>{
    // alert("hello")
    var app_array=sitestate.single_app.split('|')
    // console.log(app_array)


    // return

    let array=sitestate.apss

    let len=array.length
    var myappid=''
    if(len!=0){
      myappid=sitestate.apss
      

    }else{
      myappid=app_array[0]
      

    }
    console.log(myappid)

    console.log(myappid);


    // return 
    // var app_array=sitestate.single_app.split('|')

    setError('')

    if( !email || !password ){
      setError('Fill all the fields')

      return
    }

   

    setSpin(true)
    // console.log(app_array)


    // return

    axios.post(url+'monetize/user/login',{
      // name: firstname+' '+lastname,
      email,
      password,
      // app_id:myappid,
      plan_type:sitestate.plan,
      app_name:app_array[1]

    })
    .then(res=>{

      console.log(res);
      setSuccess(true)

      dispatch({type:'sign_up', value:false})

    }).catch(e=>{
      console.log('update')
      
      setError(e.response.data.message)

    }).then(e=>{
      setSpin(false)
      // setError('')
    })
  }

 
  return (
    <>
      {/* <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Sign Up
      </button> */}

      {success?<>

      {sitestate.plan!='freef'?
      <>
      {/* {dispatch({type:'selected_app',value:''})} */}

      <div id="alert-3" class="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
      <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
      <span class="sr-only">Info</span>
      <div class="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
        Registration complete. Please check your email to download the app.
      </div>
      <button  onClick={()=>setSuccess(false)} type="button" class="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
        <span class="sr-only">Close</span>
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </button>
    </div>

      </>:

      

      <>
      
      {dispatch({type:'selected_app',value:''})}
      {window.location.href = 'https://docsunhealth.com/contact-us/'}
      {/* <navig */}
      </>}
      {/* <Navigate to={'https://docsunhealth.com/contact-us/'} /> */}
     
      </>:null}
      {sitestate.sign_up ? (
        <>
        
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-0 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-0 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                  {sitestate.single_app.includes('heart') ? <>
                  <img src={HRLogo} className="w-96 mx-auto py-3" />
        
        
        </>:null}
        {sitestate.single_app.includes('pressure') ? <>
        <img src={BPLogo} className="w-96 mx-auto py-3" />
        
        
        </>:null}
        {sitestate.single_app.includes('stress') ? <>
        <img src={StressLogo} className="w-96 mx-auto py-3" />
      
        
        </>:null}
        {sitestate.single_app.includes('sugar') ? <>
        <img src={BSLogo} className="w-96 mx-auto py-3" />
        
        
        </>:null}
        {sitestate.single_app.includes('cholesterol') ? <>
        <img src={ChelestorolLogo} className="w-96 mx-auto py-3" />
        
        
        </>:null}

        
        
                  
        
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => dispatch({type:'sign_up', value:false})}
                  >
                    <span className="bg-transparente   text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
               
                {/*body*/}
                <div className="relative p-3 flex-auto">
                <div className="w-full max-w-md px-2 py-0 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-0">
          
            <Tab.Panel
             
              className={classNames(
                'rounded-xl bg-white p-0',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              <div className="mt-2">
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">First Name</label>
                    <input value={firstname} onChange={(e)=>setFirstName(e.target.value)} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder='First Name' />
                </div>
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Last Name</label>
                    <input value={lastname} onChange={(e)=>setLastName(e.target.value)} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Last Name'/>
                </div>
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Email'/>
                </div>
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                    <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Password'/>
                </div>
                  <div class="mb-3">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Confirm Password</label>
                    <input value={cpassword} onChange={(e)=>setCPassword(e.target.value)} type="password" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Confirm Password'/>
                </div>
                <div class="flex items-center mb-4">
                    <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 " />
                    <label for="default-checkbox" class="ml-2 text-sm font-medium ">Please contact me via email</label>
                </div>

                <div className='py-4'>
                By clicking Sign Up, I agree that I have read and accepted the
                of Use and Privacy Policy.
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b flex-col">
                <div>
                    <span className="text-red-500" >{error} {rendererror}</span>
                </div>

                {spin?<>
                  <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
                </>:null}
                 
                  <button onClick={signUp} class="w-full h-12 px-6 font-black text-white transition-colors duration-150 bg-blue-500 rounded-lg focus:shadow-outline hover:bg-blue-700 ">Sign Up</button>
                  
                  
                </div>
                  </div>
              
            </Tab.Panel>
            <Tab.Panel
             
              className={classNames(
                'rounded-xl bg-white p-3',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >

<div className="mt-0">
                  
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Email'/>
                </div>
                  <div class="mb-1">
                    <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                    <input value={password} onChange={(e)=>setPassword(e.target.value)} type="password" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  placeholder='Password'/>
                </div>
                  
                

                <div className='py-0 text-white'>
                By clicking Sign Up, I agree that I have read and accepted the
                of Use and Privacy Policy.
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b flex-col">
                <div>
                    <span className="text-red-500" >{error} {rendererror}</span>
                </div>

                {spin?<>
                  <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
                </>:null}
                 
                  <button onClick={()=>login()} class="w-full h-12 px-6 font-black text-white transition-colors duration-150 bg-blue-500 rounded-lg focus:shadow-outline hover:bg-blue-700 ">Login</button>
                  
                  
                </div>
                  </div>

              
            </Tab.Panel>
          
        </Tab.Panels>
      </Tab.Group>
    </div>
                
                </div>
                {/*footer*/}
                
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}